// BarChart.js
import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import axios from 'axios';

const BarChart = () => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://stage-backend-2024.epravaha.com/api/farmer/count/statewise');
        if (response.data && response.data.length > 0) {
          const chartData = response.data.map(item => ({
            name: item.statename,
            y: item.count
          }));
          setData(chartData);
        } else {
          setError('Empty response data');
        }
      } catch (error) {
        if (error.isAxiosError) {
          setError('Axios error: ' + error.message);
          console.error('Axios error:', error);
        } else {
          setError('Error fetching data: ' + error.message);
          console.error('Error fetching data:', error);
        }
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      {error && (
        <div>
          {error === 'Network Error' ? (
            <div>Network Error: Please check your internet connection and try again.</div>
          ) : (
            <div>Error: {error}</div>
          )}
        </div>
      )}
      {data && (
        <HighchartsReact
          highcharts={Highcharts}
          options={{
            chart: {
              type: 'bar',
              backgroundColor: '#E6E6E6', // Setting chart type to 'bar' for bar chart
            },
            title: {
              text: ''
            },
            xAxis: {
              type: 'category',
              title: {
                text: 'State'
              }
            },
            yAxis: {
              title: {
                text: 'Farmer Registration'
              }
            },
            series: [{
              name: 'Count',
              data: data,
              colorByPoint: true,
              colors: ['#96DED1','#5F9EA0', '#088F8F'] // Add as many colors as needed
            }]
          }}
        />
      )}
    </div>
  );
};

export default BarChart;
