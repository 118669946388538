// DistinctStateCount.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';

const DistinctStateCount = () => {
  const [distinctStateCount, setDistinctStateCount] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://stage-backend-2024.epravaha.com/api/farmer/count/statewise');
        if (response.data && response.data.length > 0) {
          const distinctStates = new Set(response.data.map(item => item.statename));
          setDistinctStateCount(distinctStates.size);
        } else {
          console.error('Empty response data');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="kpi" style={{backgroundColor: '#E6E6E6', padding: '20px', borderRadius: '8px', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}>
      <h3>Number of States</h3>
      <p>{distinctStateCount}</p>
    </div>
  );
};

export default DistinctStateCount;
