// App.js
import React from 'react';
import ColumnChart from './ColumnChart';
import PieChart from './PieChart';
import DonutChart from './DonutChart';
import BarChart from './BarChart';
import DistinctStateCount from './KpiStateCount';
import TotalCount from './KpiTotalCount';
import './App.css'; // Importing CSS file for styling

function App() {
  return (
    <div className="App">
      <h1 className="title">Farmers Registration Statewise</h1>
      <div className="chartContainer">
      <div className="row">
          <div className="chartWrapper">
            <DistinctStateCount />
            
          </div>
          <div className="chartWrapper">
            <TotalCount />
            
          </div>
          
        </div>
        <div className="row">
          <div className="chartWrapper">
            <ColumnChart />
            
          </div>
          <div className="chartWrapper">
            <PieChart />
          </div>
        </div>
        <div className="row">
          <div className="chartWrapper">
            <DonutChart />
          </div>
          <div className="chartWrapper">
            <BarChart />
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
